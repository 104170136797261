.parkData {
  min-width: 1200px;
  background-color: #f4f4f4;
  min-height: 100vh;
}
.parkData .head {
  width: 100%;
  background-color: #2a3137;
  height: 64px;
  line-height: 64px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 18px;
  padding-left: 40px;
}
.parkData .bodyArea {
  width: 100%;
  padding: 24px;
}
.parkData .dataArea {
  background-color: #fff;
  padding: 24px;
}
.parkData .dataArea .textArea {
  text-align: justify;
}
.parkData .iconfont {
  display: inline-block;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  margin-right: 10px;
  color: #01cae6;
}
.parkData .backControlBg {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 98px;
  height: 98px;
  background: #fff;
  border-radius: 49px;
  box-shadow: 0px 3px 7px 0px rgba(0, 192, 168, 0.35);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.parkData .backControl {
  width: 76px;
  height: 76px;
  border-radius: 38px;
  background-color: #01cae6;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
