.cardWrapper {
  display: inline-block;
}
.cardWrapper .title {
  color: #3a3a3a;
  font-size: 18px;
}
.cardWrapper .iconfont {
  font-size: 22px;
}
.cardWrapper :global .icon-fill-hexagon {
  color: #01cae6;
  font-size: 22px;
  line-height: 1;
  margin-right: 8px;
}
.cardWrapper .info {
  position: relative;
  bottom: -1px;
  font-size: 18px;
  margin-left: 10px;
  color: #8f8f8f;
  cursor: pointer;
}
