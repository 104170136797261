.controlCenter {
  position: relative;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  background: url(../assets/image/controlCenterBg.png);
}
.controlCenter .tabs {
  margin-top: 161px;
  text-align: center;
}
.controlCenter .tabs .currentTab {
  background: #38a880;
  border: 1px solid #3ab686;
  box-shadow: 1px 3px 7px 0px rgba(0, 192, 169, 0.35);
  color: white;
}
.controlCenter .tabs div {
  display: inline-block;
  height: 34px;
  width: 156px;
  line-height: 32px;
  font-size: 22px;
  border: 1px solid #3ab686;
  box-shadow: 1px 3px 7px 0px rgba(0, 192, 169, 0.35);
  opacity: 0.9;
  border-radius: 10px;
  text-align: center;
}
.controlCenter .tabs .defaultTab {
  cursor: pointer;
  background: #162c40;
}
.controlCenter .tabs .defaultTab span {
  color: #ffffff;
  background: linear-gradient(0deg, #94eceb 0.26855469%, #e9e9e9 51.02539063%, #e9e9e9 100%);
  background-clip: text;
}
.controlCenter .tabs div:not(:first-child) {
  position: relative;
  margin-left: 39px;
}
.controlCenter .tabs div:not(:first-child)::before {
  position: absolute;
  left: -22px;
  top: 14px;
  content: "";
  width: 6px;
  height: 6px;
  background: #38a880;
  border-radius: 50%;
}
.controlCenter .btns div {
  position: absolute;
  top: 418px;
  width: 116px;
  left: 596px;
  height: 68px;
  cursor: pointer;
}
.controlCenter .btns div:last-child {
  left: 1185px;
}
.controlCenter .carousel {
  width: 451px;
  height: 355px;
  margin: 98px auto;
}
.controlCenter .carousel .imgArea {
  position: relative;
  height: 355px;
}
.controlCenter .carousel .imgArea .imgPositon1 {
  width: 230px;
  margin: 0 auto;
}
.controlCenter .carousel .imgArea .imgPositon2 {
  position: absolute;
  left: 50%;
  z-index: 2;
  margin: 30px -135px;
  width: 270px;
}
.controlCenter .carousel .imgArea .enterprise_rotate {
  top: 139px;
  z-index: 2;
  position: absolute;
  width: 451px;
  height: 300.5px;
  border-radius: 50% 50%;
  transform: rotateX(80deg) rotateY(0deg) rotateZ(0deg);
}
.controlCenter .carousel .imgArea .enterprise_rotate :local {
  animation: rotateImg 4s linear infinite;
}
.controlCenter .carousel .imgArea .enterprise_rotate img {
  top: 50%;
  left: 50%;
  margin-top: -220px;
  margin-left: -217.3px;
  position: absolute;
  width: 440px;
  height: 434.6px;
}
.controlCenter .carousel .imgArea .enterprise_rotate .opacityImg {
  opacity: 0.1;
}
.controlCenter .carousel .imgArea .enterprise_guangzhu {
  z-index: 1;
  top: 77px;
  position: absolute;
}
.controlCenter .parkListArea {
  width: 1701px;
  margin: 20px auto;
}
.controlCenter .parkListArea .parkItem {
  display: inline-block;
  width: 333px;
  height: 36px;
  background: #162c40;
  border: 1px solid #3ab686;
  box-shadow: 1px 3px 7px 0px rgba(0, 192, 169, 0.35);
  border-radius: 10px;
  line-height: 36px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  cursor: pointer;
  font-weight: 400;
  color: white;
  text-align: center;
  margin-right: 9px;
  margin-top: 10px;
}
.controlCenter .parkListArea .parkItem:nth-child(5n + 5) {
  margin-right: 0px;
}
.controlCenter .parkListArea .parkItem[ischecked='true'] {
  background: #08a868;
}
.controlCenter .parkListArea .parkItem[ischecked='false'] {
  background: #162c40;
}
@keyframes rotateImg {
  form {
    transform: rotateX(80deg) rotateY(0deg) rotateZ(0deg);
  }
  to {
    transform: rotateX(80deg) rotateY(0deg) rotateZ(360deg);
  }
}
