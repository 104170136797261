.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
  margin-bottom: 32px;
}
.column .info {
  position: relative;
  bottom: -1px;
  font-size: 18px;
  margin-left: 10px;
  color: #8f8f8f;
  cursor: pointer;
}
.column .title {
  width: 100%;
  color: #8f8f8f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.column .number {
  width: 100%;
  font-size: 24px;
  line-height: 1;
  color: #01cae6;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.company {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}
.company .column {
  margin-bottom: 0;
}
.company .iconfont {
  font-size: 60px;
}
.company :global .iconfont {
  width: 55px;
  min-width: 55px;
  height: 65px;
  line-height: 65px;
  font-size: 60px;
  color: #01cae6;
  text-align: center;
  margin-right: 8px;
  overflow: hidden;
}
.haschild::after {
  display: inline-block;
  position: absolute;
  right: 16%;
  top: 0;
  content: "";
  height: calc(100% - 20px);
  width: 1px;
  transform: translateX(-10%);
  background-color: #e6e6e6;
}
@media (min-width: 992px) {
  .haschild::after {
    right: 20%;
  }
}
@media (min-width: 1200px) {
  .haschild::after {
    right: 4%;
  }
}
@media (min-width: 1400px) {
  .haschild::after {
    right: 16%;
  }
}
.showMore {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 34px;
  background: #01cae6;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
}
.showMore .zhankai {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid white;
}
.showMore .shouqi {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid white;
}
.showMore .text {
  margin-left: 12px;
}
